<template>
  <date-range-picker
    v-model="dateRange"
    :locale-data="localeData"
    class="d-flex"
    :opens="opens"
    :auto-apply="true"
    :time-picker="false"
    :ranges="hideRanges ? false: ranges"
    placeholder="dasdasda"
    @update="handleChange"
  >
    <template
      v-slot:input="slotProps"
    >
      {{ getInputLabel(slotProps.rangeText) }}
    </template>
  </date-range-picker>
</template>

<script>
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRangePicker from 'vue2-daterange-picker'

const ranges = {
  Today: [
    moment().hour(0).minute(0).second(0)
      .toDate(),
    moment().hour(23).minute(59).second(59)
      .toDate(),
  ],
  Yesterday: [
    moment().subtract(1, 'day').hour(0).minute(0)
      .second(0)
      .toDate(),
    moment().subtract(1, 'day').hour(23).minute(59)
      .second(59)
      .toDate(),
  ],
  'Day Before Yesterday': [
    moment().subtract(2, 'day').hour(0).minute(0)
      .second(0)
      .toDate(),
    moment().subtract(2, 'day').hour(23).minute(59)
      .second(59)
      .toDate(),
  ],
  'Last 7 Days': [
    moment().subtract(7, 'day').hour(0).minute(0)
      .second(0)
      .toDate(),
    moment().hour(23).minute(59)
      .second(59)
      .toDate(),
  ],
  'This Month': [
    moment().startOf('month').hour(0).minute(0)
      .second(0)
      .toDate(),
    moment().hour(23).minute(59)
      .second(59)
      .toDate(),
  ],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month').hour(0)
      .minute(0)
      .second(0)
      .toDate(),
    moment().subtract(1, 'month').endOf('month').hour(23)
      .minute(59)
      .second(59)
      .toDate(),
  ],
  'Last 30 Days': [
    moment().subtract(30, 'day').hour(0).minute(0)
      .second(0)
      .toDate(),
    moment().hour(23).minute(59)
      .second(59)
      .toDate(),
  ],
  'Last 6 Months': [
    moment().subtract(7, 'month').startOf('month').hour(0)
      .minute(0)
      .second(0)
      .toDate(),
    moment().subtract(1, 'month').endOf('month').hour(23)
      .minute(59)
      .second(59)
      .toDate(),
  ],
  'Last 12 Months': [
    moment().subtract(13, 'month').startOf('month').hour(0)
      .minute(0)
      .second(0)
      .toDate(),
    moment().subtract(1, 'month').endOf('month').hour(23)
      .minute(59)
      .second(59)
      .toDate(),
  ],
  'Last Year': [
    moment().subtract(1, 'year').startOf('year').hour(0)
      .minute(0)
      .second(0)
      .toDate(),
    moment().subtract(1, 'year').endOf('year').hour(23)
      .minute(59)
      .second(59)
      .toDate(),
  ],
  'Year to Date': [
    moment().startOf('year').hour(0).minute(0)
      .second(0)
      .toDate(),
    moment().hour(23).minute(59)
      .second(59)
      .toDate(),
  ],
  'All Time': [
    moment('2010-01-01', 'YYYY-MM-DD').toDate(),
    moment().hour(0).minute(0).second(0)
      .toDate(),
  ],
}

export default {
  name: 'DateRange',
  components: {
    DateRangePicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    opens: {
      type: String,
      default: 'right',
    },
    localeData: {
      type: Object,
      default: () => ({ firstDay: 1, format: 'mmm d, yyyy' }),
    },
    hideRanges: {
      type: Boolean,
      default: false,
    },
    disableRangeLabel: {
      type: Boolean,
      default: false,
    },
    rangeOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateRange: {},
      ranges: {},
      rangeKey: undefined,
    }
  },
  created() {
    if (Object.entries(this.rangeOptions).length) {
      this.ranges = this.rangeOptions
    } else {
      this.ranges = ranges
    }
    this.dateRange = JSON.parse(JSON.stringify(this.value))
    this.rangeKey = this.findRangeKey()
  },
  methods: {
    handleChange(value) {
      const data = { ...value, rangeLabel: this.findRangeKey() || 'Custom' }
      if (data.startDate) {
        data.startDate = moment(data.startDate).hour(0).minute(0)
          .second(0)
          .toDate()
      }

      if (data.endDate) {
        data.endDate = moment(data.endDate).hour(23).minute(59)
          .second(59)
          .toDate()
      }

      this.$emit('input', value)
      this.$emit('update', data)
    },
    getInputLabel(defaultLabel) {
      if (this.disableRangeLabel) {
        return defaultLabel
      }
      const label = this.findRangeKey()
      if (label) {
        return label
      }
      return defaultLabel
    },
    findRangeKey() {
      return Object.keys(this.ranges)
        .find(key => this.isSame(this.dateRange.startDate, this.ranges[key][0], 'date') && this.isSame(this.dateRange.endDate, this.ranges[key][1], 'date'))
    },
    isSame(date1, date2, granularity) {
      const dt1 = new Date(date1)
      const dt2 = new Date(date2)
      if (granularity === 'date') {
        dt1.setHours(0, 0, 0, 0)
        dt2.setHours(0, 0, 0, 0)
      }

      return dt1.getTime() === dt2.getTime()
    },
  },
}
</script>

<style scoped>

</style>
